// App.js
import React, { useState, useEffect } from 'react';
import Graboncampaign from './graboncampaign1';
import './webprofile.css';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
const Templaterenderer = () => {
    const [data, setData] = useState(null);
    const [template, setTemplate] = useState(null);
    const [busdata,setbusdata]=useState(null)
    const { id } = useParams();
  
    const showLoader = () => {
      return (
        <div className="bouncing-ball">
          
        
        <div className="bouncing-ball">
          
          </div>
        </div>
      );
    }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const host = window.location.hostname;
            console.log('subdomain',host)
        const response=await fetch(`https://us-central1-jiffybook-public.cloudfunctions.net/getpublicbusinesswebsectiondata?parameter=${host}`) 
        const result = await response.json();
         console.log('subdatafetched',result.country)
       
      
        
            if(result.country=='IN'){
                const response1=await fetch(`https://us-central1-jiffybook-india.cloudfunctions.net/getcampaigndata?parameter=${id}`)
                const result1 = await response1.json();
                //setData(result1.data); 
                const response=await fetch(`https://us-central1-jiffybook-india.cloudfunctions.net/getsubdomainprofiledata?parameter=${host}`)
                const result = await response.json();
                //setbusdata(result.busdata)
                const combinedData = {
                  ...result1.data,
                  ...result.busdata,
                };
                
                setData(combinedData);
               // console.log('fetching',result1.data,result.busdata)
                setTemplate(result1.data.Template)
            }
      
       
        if(result.country=='EE'){
          const response1=await fetch(`https://us-central1-jiffybook-app.cloudfunctions.net/getcampaigndata?parameter=${id}`)
          const result1 = await response1.json();
          const response=await fetch(`https://us-central1-jiffybook-app.cloudfunctions.net/getsubdomainprofiledata?parameter=${host}`)
                const result = await response.json();
                setbusdata(result.busdata)
          setData(result1.data); 
          setTemplate(result1.data.Template)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    }, []);
  
    if (!data) {
      return  <div style={{marginLeft:"45%",marginTop:'20%'}}>
      <div className="bouncing-ball">
        {showLoader()}
        </div>
     </div>;
    }
    switch (template) {
        case 'A':
          return <Graboncampaign data={data} />;
      
        default:
          return  <Graboncampaign data={data} />;
      }

};

export default Templaterenderer;
