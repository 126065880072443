import React, { useState, useEffect } from 'react';
import "./graboncampaign.css";
import moment from 'moment';
import goldeisimg from './jiffygoldies_o_web.png'
import { Alert, Button, TextInput } from 'evergreen-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram,faWhatsapp, faGoogle, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope,faPhone } from '@fortawesome/free-solid-svg-icons'; // Email icon from solid
import axios from "axios";
import userimg from '../pages/users.png'

const Graboncampaign = (fetcheddata) => {
console.log('datafetch',fetcheddata)
  const [isScrolled, setIsScrolled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [section8background,setsection8background]=useState('')
  const [section8headercolor,setsection8headercolor]=useState('')
  const [section8headerfont,setsection8headerfont]=useState('')
  const [section8parafont,setsection8parafont]=useState('')
  const [section8paracolor,setsection8paracolor]=useState('')
  const [section8reftextcolor,setsection8reftextcolor]=useState('')
  const [dynamicmanifest,setdynamicmanifest]=useState('')
  const [section8invitetext,setsection8invitetext]=useState('')
  const [section8invitefont,setsection8invitefont]=useState('')
  const [section8invitefontsize,setsection8invitefontsize]=useState('')
  const [section8invitetextcolor,setsection8invitetextcolor]=useState('')
  const [section8footertext,setsection8footertext]=useState('')
  const [section8footerfontsize,setsection8footerfontsize]=useState('')
  const [section8footerfont,setsection8footerfont]=useState('')
  const [section8footertextcolor,setsection8footertextcolor]=useState('')
  const [section9,setsection9]=useState('')
  const [busmail,setbusmail]=useState('')
  const [regheaderfontsize,setregheaderfontsize]=useState('')
  const [regtextfontsize,setregtextfontsize]=useState('')
  const [regheader,setregheader]=useState('')
  const [regtext,setregtext]=useState('')
  const [successalert,setsuccessalert]=useState(false)
  const [imageload,setimageload]=useState(false)
  const [email,setemail]=useState('')
  const [referalcode,setreferalcode]=useState('')
  const [referalcodeinput,setreferalcodeinput]=useState(false)
  const [errormessage,setErrormessage]=useState('')
  const [buttontext,setButtontext]=useState('Send Invitation')
  const [countrycode,setcountrycode] = useState('');
  const [BID256,setBID256]=useState('')
  const [businessname, setbusinessname] = useState("");
const [businesslogo, setbusinesslogo] = useState("");
const [businessprofile, setbusinessprofile] = useState("");
const [busphone,setbusphone]=useState('')
  function referalcodeinputview(){
    setreferalcodeinput(true)
  }
  function onemailchange(e){
      setErrormessage('')
    setemail(e.target.value)}
    function onreferallcodechange(e){
      setreferalcode(e.target.value)
    }
    function onemailend (e) {
      setErrormessage('')
      setemail(e.target.value)
      //validateEmail(e.target.value)
      
      if(email!=''){
          if(!validateEmail(e.target.value)){
            setErrormessage('Invalid')
          }
          else{
            setemail(e.target.value)
          }
         }
        }
    
    function  validateEmail (email){
      setErrormessage('')
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    
      }
    function submit() {
        
       if(email==''){
      setErrormessage('Email required')
       } 
       else if(errormessage==''){
        var posturl='';
        setButtontext("Sending")
        if(countrycode=='IN'){
          posturl='https://us-central1-jiffybook-india.cloudfunctions.net/Consumercommunityreg'
        }
        else if(countrycode=='EE'){
          posturl='https://us-central1-jiffybook-app.cloudfunctions.net/Consumercommunityreg'
           
        }
        axios({
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            //url:'https://us-central1-jiffybook-preprod.cloudfunctions.net/Consumercommunityreg',
            url:posturl,
            //url:'https://us-central1-jiffybook-india-preprod.cloudfunctions.net/Consumercommunityreg',
            data:{
              CMail:email,
              CPhone:countrycode+busphone,
              Bid:BID256,
              Bname:businessname,
              strBlogo:businesslogo,
              strBprofileimage:businessprofile,
              strBname:businessname,
              strCouponCode:referalcode.toLocaleUpperCase()
  
    
            },
          
          }).then(
            setTimeout(function(){
              setsuccessalert(true)
              
           // window.location.href='https://preprod-gallery-profile.web.app/Profile/'+BID256
              },2000),
              setTimeout(function(){
             setemail('')
             setButtontext("Send Invitation")
             setsuccessalert(false)
             setreferalcode('')
             setreferalcodeinput(false)
                },4000),
              console.log('posted',posturl))
              
    
        }
      }
  useEffect(() => {
    setsection8background(fetcheddata.data.section8.Backgroundcolor)
    setsection8reftextcolor(fetcheddata.data.section8.Referaltextcolor)
    setsection8headercolor(fetcheddata.data.section8.Headercolor)
    setsection8headerfont(fetcheddata.data.section8.Headerfont)
    setsection8paracolor(fetcheddata.data.section8.Paracolor)
    setsection8parafont(fetcheddata.data.section8.Parafont)
    setsection8invitetext(fetcheddata.data.section8.Invitetext)
    setsection8invitefontsize(fetcheddata.data.section8.Invitefontsize)
    setsection8invitefont(fetcheddata.data.section8.Invitefontfamily)
    setsection8invitetextcolor(fetcheddata.data.section8.Invitetextcolor)
    setsection8footertext(fetcheddata.data.section8.Footertext)
    setsection8footerfontsize(fetcheddata.data.section8.Footerfontsize)
    setsection8footerfont(fetcheddata.data.section8.Footerfontfamily)
    setsection8footertextcolor(fetcheddata.data.section8.Footertextcolor)
    setbusmail(fetcheddata.data.busmail)
    setsection9(fetcheddata.data.section9)
    setregheaderfontsize(fetcheddata.data.section8.Headerfontsize)
    setregtextfontsize(fetcheddata.data.section8.Parafontsize)
    setregheader(fetcheddata.data.section8.Regheader)
    setregtext(fetcheddata.data.section8.Regtext)
    setbusinessname(fetcheddata.data.busname)
    setbusinessprofile(fetcheddata.data.busprofile)
    setbusinesslogo(fetcheddata.data.buslogo)
    setcountrycode(fetcheddata.data.countrycode)
    setBID256(fetcheddata.data.BID256)
   
    document.title=fetcheddata.data.section1.Title;
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = fetcheddata.data.section1.Titlelogo;
  document.getElementsByTagName('head')[0].appendChild(link);

   /* const timeDiffMs = Math.abs(timestamp2Date - timestamp1Date);
    console.log('diff',timeDiffMs)
    const duration = moment.duration(timestamp1Date.diff(timestamp2Date));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    console.log(`${days}   
 days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`)*/
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  window.addEventListener('scroll', handleScroll);
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
 
  //return () => clearInterval(timer);
})
useEffect(() => {
  const calculateTimeLeft = () => {
    
    const targetTime = moment.unix(fetcheddata.data.strEnd._seconds);
    const currentTime = moment();

    if (currentTime.isBefore(targetTime)) {
      const diffDuration = moment.duration(targetTime.diff(currentTime));

      // Extract days, hours, minutes, and seconds
      const days = Math.round(diffDuration.asDays());
      const hours = diffDuration.hours();
      const minutes = diffDuration.minutes();
      const seconds = diffDuration.seconds();
      setTimeLeft(`${days} : ${hours} : ${minutes} : ${seconds}`);
      console.log('timeleft',
        timeLeft
      );
    } else {
      setTimeLeft("00 : 00 : 00 : 00");
    }
  };

  // Update timer every second
  const timer = setInterval(calculateTimeLeft, 1000);
 return () => clearInterval(timer);
}, [isScrolled]);

  return (
    <div >
         <head>
         <meta property="og:image" content={fetcheddata.data.section1.Titlelogo} />
          <meta property="og:title" content={fetcheddata.data.section1.Title} />
 
         <link rel="icon" href={fetcheddata.data.section1.Titlelogo} />
         <link rel="apple-touch-icon" href={fetcheddata.data.section1.Titlelogo} />
         <link href='https://fonts.googleapis.com/css?family=Great Vibes' rel='stylesheet'></link>
         <link href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap" rel="stylesheet"></link>
<link href="https://fonts.googleapis.com/css2?family=Sacramento&display=swap" rel="stylesheet"></link>
      </head>
      {/* Header Section */}
      <header className={`header ${isScrolled ? 'scrolled' : ''}`} 
    style={{backgroundColor:isScrolled?fetcheddata.data.section1.Headerbackgroundonscroll:fetcheddata.data.section1.Headerdefaultbackground,
    fontSize:12,borderStyle:'none',height:90,fontWeight:'lighter'}}>
      <img src={fetcheddata.data.strSection1.strBlogo} alt="Logo" className="logo" />
      <h1 style={{fontWeight:'lighter',fontFamily:fetcheddata.data.section1.Font.replace(/^"(.*)"$/, '$1'),color:isScrolled?fetcheddata.data.section1.Fontcoloronscroll:fetcheddata.data.section1.Fontcolor,fontSize:fetcheddata.data.section1.Fontsize
      }}
      >{fetcheddata.data.strSection1.Tagline}</h1>
    </header>

      {/* Promo Section */}
      <section className="promo-section">
        <div className="promo-text">
          <p className="subtitle" style={{fontFamily:fetcheddata.data.strSection1.Para1font,fontSize:fetcheddata.data.strSection1.Para1fontsize,color:fetcheddata.data.strSection1.Para1color}}
          >{fetcheddata.data.strSection1.Para1text}</p>
          <h1 className="title" style={{fontFamily:fetcheddata.data.strSection1.Headerfont,fontSize:fetcheddata.data.strSection1.Headerfontsize,color:fetcheddata.data.strSection1.Headercolor}}
          
          >
            {fetcheddata.data.strSection1.Headertext}
          </h1>
         
          <ul className="features">
      {Object.values(fetcheddata.data.strSection1.Bullets).map((text, index) => (
        <li key={index} style={{fontFamily:fetcheddata.data.strSection1.Bulletsfont,fontSize:fetcheddata.data.strSection1.Bulletfontsize,color:fetcheddata.data.strSection1.Bulletscolor}}
      >✅ {text}</li>
      ))}
    </ul>
          <div className="deal-section">
            <button className="claim-deal">{fetcheddata.data.strStartText}</button>
            <div className="timer">{timeLeft}</div>
          </div>
          <p className="guarantee" style={{fontFamily:fetcheddata.data.strSection1.Para2font,fontSize:fetcheddata.data.strSection1.Para2fontsize,color:fetcheddata.data.strSection1.Para2color}}
        
          >{fetcheddata.data.strSection1.Para2text}</p>
        </div>
        {fetcheddata.data.strSection1.strMediaURL.includes('.mp4') ||fetcheddata.data.strSection1.strMediaURL.includes('.webm')?(
        <div className="promo-image">
        <video  preload="auto" controls loop autoplay src={fetcheddata.data.strSection1.strMediaURL} width="50%" height="10%" />
      </div>):(
        <div className="promo-image">
          <img src={fetcheddata.data.strSection1.strMediaURL}  />
        </div>)}
      </section>


      <div className='registration' style={{backgroundColor:section8background}}>
  <img className='goldiesimg' src={goldeisimg}></img>
    < h2 className='regheader' style={{fontSize:regheaderfontsize,fontFamily:section8headerfont,color:section8headercolor}}>{regheader}
    </h2>
    <p className='regtext' style={{fontSize:regtextfontsize,fontFamily:section8parafont,color:section8paracolor}}>{regtext}</p>
  {successalert==true?(<Alert
    intent="success"
    title='Invitation sent'
    marginTop={10}
    marginLeft={'15%'}
    width={'65%'}
  />):null}
        {imageload==false?(
         
      <div className='inputs'>
        <div style={{flexDirection:'row',borderColor:'#e75600'}}>
      <TextInput
        value={email}
        onChange={onemailchange}
        onBlur={onemailend}
        placeholder={email?email:'Email'}
        style={{backgroundColor:'black',color:'white',borderColor:'#e75600'}}
      /> <br></br>
     
      </div>
      
    </div>):null} 

     {imageload==false&&referalcodeinput==true?(
         
         <div className='inputs'>
           <div style={{flexDirection:'row',borderColor:'#e75600'}}>
         <TextInput
           value={referalcode}
           onChange={onreferallcodechange}
           placeholder={referalcode?referalcode:'Referal code'}
           style={{backgroundColor:'black',color:'white',borderColor:'#e75600'}}
         /> <br></br>
        
         </div>
         
       </div>):(<div className='inputs' >
        <Button onClick={referalcodeinputview} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
       <span style={{color:'#e75600',fontSize:21}} > &#10753;&nbsp;</span>
       <span style={{color:'white',fontSize:14,color:section8reftextcolor}}>Add referral code?</span> 
       </Button>
       </div>)}
    {imageload==false?(
    <div style={{justifyContent:'center',alignItems:'center'}}>
         <Button className="submitbutton" style={{backgroundColor:'#e75600',color:'white',borderRadius:20,
        marginTop:15,fontSize:14,height:24,fontWeight:'bold',borderColor:'#e75600',width:270,}}
         onClick={submit}>{buttontext.toLocaleUpperCase()}
         <img id="weburl" style={{borderRadius:20}} src={userimg} className='userimage' ></img>
     
         </Button>
      
    </div>):null}
    {errormessage!=''?(
    <Alert
    intent="danger"
    title={errormessage}
    width={'65%'}
    fontWeight={'bold'}
    textAlign={'center'}
    marginTop={10}
  />):null}
  <div style={{marginTop:50}}>
  <h1 style={{ fontSize: section8invitefontsize, fontFamily: section8invitefont, color: section8invitetextcolor, 
    textAlign: "center" }}>
    {section8invitetext}
  </h1>
  
  <h5 style={{ textAlign: "center" }}>
    <a href="https://play.google.com/store/apps/details?id=com.indreamer.jiffybook">
      <img
        width="200"
        src="https://firebasestorage.googleapis.com/v0/b/jiffybook-public.appspot.com/o/jiffybook.indreamer.com%2Fplay-store-badge.png?alt=media&token=191b000c-08b5-4238-aa03-a8400755351d"
        alt="Play Store Badge"
      />
    </a>
    <br />
    <a href="https://apps.apple.com/app/id1514749994">
      <img
        width="200"
        src="https://firebasestorage.googleapis.com/v0/b/jiffybook-public.appspot.com/o/jiffybook.indreamer.com%2Fapp-store-badge.png?alt=media&token=28b3781e-4eff-4314-8919-dd485f4e9949"
        alt="App Store Badge"
      />
    </a>
  </h5>
</div>
<div style={{flexDirection:'row',display:'flex'}}>
  <div style={{ textAlign: 'left' ,display:'flex',flexDirection:'column',}}>
    <span style={{color:section9.Contacttextcolor,textAlign:'center',fontSize:section9.Contactfontsize,
      font:section9.Contactfont,fontWeight:'bold'
    }}>
      {section9.Contacttext?section9.Contacttext:'Contact'}</span>
    
    <div style={{flexDirection:'row',marginTop:5}}>
    {busphone?(<a href={"tel:"+busphone} >
  <FontAwesomeIcon icon={faPhone} className='iconstyle' />
  </a>):null}
    {busmail?(
  <a href={"mailto:"+busmail}>
    <FontAwesomeIcon icon={faEnvelope} className='iconstyle' />
  </a>):null}
  {section9.Whatsapp?(
    <a href={section9.Whatsapp}>
    <FontAwesomeIcon icon={faWhatsapp} className='iconstyle' />
  </a>
  ):null}
  
  </div>
  </div>
<div style={{ textAlign: 'left' ,display:'flex',flexDirection:'column',marginLeft:60}}>
  <span style={{color:section9.Socialmediatextcolor,font:section9.Socialmediafont,fontSize:section9.Socialmediatextfontsize,
    fontWeight:'bold',textAlign:'center'}}>{section9.Socialmediatext?section9.Socialmediatext:'Follow us on'}</span>
  <div style={{flexDirection:'row',marginTop:5}}>
    {section9.Facebook?(
      <a href={section9.Facebook} target="_blank" rel="noopener noreferrer" className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Facebookiconcolor}}>
        <FontAwesomeIcon icon={faFacebook} />
      </a>):null}
      {section9.Twitter?(
      <a href={section9.Twitter} target="_blank" rel="noopener noreferrer" className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Twittericoncolor}}>
        <FontAwesomeIcon icon={faTwitter} />
      </a>):null}
      {section9.Instagram?(
      <a href={section9.Instagram} target="_blank"  className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Instagramiconcolor}}>
        <FontAwesomeIcon icon={faInstagram} />
      </a>):null}
      {section9.Youtube?(
      <a href={section9.Youtube} target="_blank"  className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Youtubeiconcolor}}>
        <FontAwesomeIcon icon={faYoutube} />
      </a>):null}
      </div>
    </div>
    </div>
 <footer style={{color:section8footertextcolor,fontSize:section8footerfontsize,
  fontFamily:section8footerfont,marginTop:25,marginBottom:10}}>{section8footertext}</footer> 
  </div>
    </div>
  );
};

export default Graboncampaign;
